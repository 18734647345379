import titleHelper from "./titleHelper";
import PropTypes from "prop-types";

const TextArea = ({ name, handleChange, isDisabled, content }) => (
    <>
        <div className='textColumn'>
            {titleHelper({ name })}
            <textarea
                onChange={handleChange}
                name={name}
                id={name}
                cols='10'
                rows='5'
                disabled={isDisabled}
                defaultValue={content || ""}
            />
        </div>
    </>
);

TextArea.propTypes = {
    name: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool,
    content: PropTypes.string,
};

export default TextArea;
