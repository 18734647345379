const titleHelper = ({ name }) => {
    const titles = {
        report: "FAILURE REPORT",
        cause: "DESCRIPTION",
        assistant: "GO NAME",
        client: "CLIENT NAME",
    };

    return (
        <label
            id={name}
            className='label'>
            {titles[name]}
        </label>
    );
};

export default titleHelper;
