import React from "react";
import $ from "jquery";
import "./style.css";
import GenerateTicket from "../utils/generateTicket";
import TicketTemplate from "../TicketTemplate/TicketTemplate";
import renderCheckBoxes from "../Helpers/renderCheckBoxes";
import "../../css/cards.css";
import filterAll from "../utils/filterAll";
import filterBy from "../utils/filterBy";
// import DropDown from "../TicketForm/Dropdown/DropDown.js";
import SearchBar from "../CommonComponents/SearchBar";
import config from "../../main-config.json";
import PropTypes from "prop-types";

const URL = config.failures_api_url;

const CHECKBOX_QUERY_SELECTOR = "input[type='checkbox']";

class Pending extends React.Component {
    constructor() {
        super();

        this.state = {
            ticket: [],
            issueTicket: [],
            filteredTicket: [],
            groupByTicket: [],
            itemData: {},
            issue: "",
            search: "",
            filterAll: [],
            filterBy: "",
            groupBy: [
                "MECHANICAL ISSUE",
                "SOFTWARE ISSUE",
                "HARDWARE ISSUE",
                "client",
                "broke from customers",
            ],
            department: ["electronic", "other"],
            showPendings: true,
            showTicketForm: false,
            hasSearched: false,
            deleteConfirmation: false,
        };

        this.handleCheckboxFilter = this.handleCheckboxFilter.bind(this);
        this.handleDepartmentType = this.handleDepartmentType.bind(this);
    }

    handleCheckboxFilter(event) {
        const checkbox = event.target.name;
        const isChecked = event.target.checked;
        const { issueTicket } = this.state;
        document
            .getElementById("groupBy")
            .querySelectorAll(CHECKBOX_QUERY_SELECTOR)
            .forEach((el) => {
                el.checked = false;
                if (el.name === checkbox && el.checked !== isChecked) {
                    el.checked = true;
                    let filteredTicket = filterBy(
                        issueTicket,
                        el.name,
                        "groupBy"
                    );
                    this.setState({
                        filteredTicket,
                        showPendings: true,
                        search: "",
                        hasSearched: false,
                    });
                }
            });
    }

    handleDepartmentType = (event) => {
        const checkbox = event.target.name;
        const isChecked = event.target.checked;
        const { ticket } = this.state;
        document
            .getElementById("department")
            .querySelectorAll(CHECKBOX_QUERY_SELECTOR)
            .forEach((el) => {
                el.checked = false;
                if (el.name === checkbox && el.checked !== isChecked) {
                    el.checked = true;
                    let filteredTicket = filterBy(
                        ticket,
                        el.name,
                        "department"
                    );
                    this.setState({
                        filteredTicket,
                        showPendings: true,
                        search: "",
                        hasSearched: false,
                    });
                }
            });
    };
    handleIssueType = (event) => {
        const checkbox = event.target.name;
        // const isChecked = event.target.checked;
        const { ticket } = this.state;
        document
            .getElementById("checkboxes")
            .querySelectorAll(CHECKBOX_QUERY_SELECTOR)
            .forEach((el) => {
                el.checked = false;
                if (el.name === checkbox) {
                    el.checked = true;
                    let filteredTicket = filterBy(
                        ticket,
                        el.name,
                        "checkboxes"
                    );
                    this.setState({
                        filteredTicket,
                        showPendings: true,
                        search: "",
                        hasSearched: false,
                    });
                }
            });
    };

    editTicket = (itemData) => {
        this.setState(
            {
                itemData,
            },
            () => {
                this.setState({
                    showTicketForm: true,
                    showPendings: false,
                    showTickets: false,
                    hasSearched: false,
                });
            }
        );
    };

    deleteTicket = (itemData) => {
        // console.log(itemData);
        this.setState(
            {
                itemData,
            },
            () => {
                this.setState({
                    deleteConfirmation: true,
                });
            }
        );
    };

    removeTicketFromList = (id) => {
        // console.log(id);
        let tempTicketList = this.state.ticket;

        let newTicketList = tempTicketList.filter((el) => el.id !== id);

        this.setState({
            ticket: newTicketList,
        });

        let newTicketCounter = 0;
        let oldTicketCounter = this.props.pendingsStatus;

        newTicketCounter = oldTicketCounter - 1;
        this.props.handleTicketCounter(newTicketCounter);
    };

    submitDelete = (e) => {
        e.preventDefault();
        let itemData = this.state.itemData;
        $.ajax({
            type: "POST",
            url: URL + "/ticket/deleteTicket.php",
            data: {
                id: parseInt(itemData.id),
            },
            success: (response) => {
                if (response.message === "success") {
                    this.setState({
                        deleteConfirmation: false,
                    });
                    this.removeTicketFromList(itemData.id);
                }
            },
        });
    };

    updateSearch(event) {
        let tempSearchTickets = [];

        if (event.target.value !== "") {
            this.setState({
                hasSearched: true,
                showTicketForm: false,
            });
        } else {
            this.setState({
                hasSearched: false,
                showTickets: true,
            });
        }

        this.setState(
            {
                search: event.target.value.substr(0, 20),
            },
            () => {
                if (this.state.filterBy === "") {
                    tempSearchTickets = filterAll(
                        this.state.filteredTicket,
                        this.state.search
                    );
                } else {
                    let tempFilterBy = this.state.filterBy;

                    tempSearchTickets = filterBy(
                        this.state.filteredTicket,
                        this.state.search,
                        tempFilterBy
                    );
                }

                this.setState({
                    showPendings: true,
                    groupByTicket: tempSearchTickets,
                });
            }
        );
    }

    showPendingTickets = (ticketsToMap) =>
        ticketsToMap.map((item, index) => (
            <GenerateTicket
                key={index}
                item={item}
                editTicket={item.solution === "" ? this.editTicket : undefined}
                deleteTicket={
                    this.props.profile === "admin"
                        ? this.deleteTicket
                        : undefined
                }
            />
        ));

    handleTicketCounterPending = (newTicketCounter) => {
        this.props.handleTicketCounter(newTicketCounter);
    };

    resetshowTicketForm = () => {
        this.setState({
            showTicketForm: false,
            showPendings: true,
            deleteConfirmation: false,
        });
    };

    updateTicketList = (itemData) => {
        let ticketList = this.state.ticket;

        ticketList.map((el) => {
            if (el.id === itemData.id) {
                Object.assign(el, itemData);
            }
        });

        this.setState({
            showTicketForm: false,
            showPendings: true,
        });
    };

    componentDidMount() {
        $.ajax({
            type: "POST",
            url: URL + "/ticket/fetchPendingTickets.php",
            data: {
                status: "pending",
            },
            success: (response) => {
                // console.log(response.ticket);
                if (response.message === "No Pending Results!") {
                    this.setState({
                        showTickets: false,
                    });
                } else {
                    this.setState(
                        {
                            ticket: response.ticket,
                            showTickets: true,
                        },
                        () => {
                            let filteredTicket = filterBy(
                                response.ticket,
                                "electronic",
                                "department",
                                "groupBy"
                            );
                            this.setState({
                                filteredTicket,
                            });
                        }
                    );
                }
            },
        });
    }

    render() {
        return (
            <div className='back'>
                {this.state.showPendings && (
                    <SearchBar
                        searchInput={this.state.search}
                        updateSearch={this.updateSearch.bind(this)}
                    />
                )}
                {this.state.showPendings && (
                    <>
                        <div className='filterSearchContainer row-reverse'>
                            <div className='filterCheckBoxes'>
                                <h2>Department</h2>
                                <div id='department'>
                                    {renderCheckBoxes(
                                        this.state.department,
                                        this.handleDepartmentType.bind(this)
                                    )}
                                </div>
                                {/* <div id="groupBy">
                                <h2>Issues</h2>
                                {renderCheckBoxes(
                                    this.state.groupBy,
                                    this.handleCheckboxFilter.bind(this)
                                )}
                            </div> */}
                                {/* <h2>Group By</h2>
                            <div id="groupBy">
                                {renderCheckBoxes(
                                    this.state.checkboxes,
                                    this.handleCheckboxFilter.bind(this)
                                )}
                            </div> */}
                            </div>

                            <ul className='cards scrollable'>
                                {this.state.hasSearched &&
                                this.state.groupByTicket.length === 1 ? (
                                    this.showPendingTickets(
                                        // this.handleCheckboxFilter.bind(this),
                                        this.state.groupByTicket
                                    )
                                ) : this.state.hasSearched &&
                                  this.state.groupByTicket.length < 1 ? (
                                    <p className='resultP'>
                                        {" "}
                                        No results found!{" "}
                                    </p>
                                ) : (
                                    this.showPendingTickets(
                                        this.state.filteredTicket
                                    )
                                )}
                            </ul>
                        </div>
                    </>
                )}
                {this.state.showTicketForm && (
                    <TicketTemplate
                        data={this.state.itemData}
                        calledFromPending={true}
                        resetshowTicketForm={this.resetshowTicketForm}
                        handleTicketCounterPending={
                            this.handleTicketCounterPending
                        }
                        pendingsStatus={this.props.pendingsStatus}
                        isAdmin={this.props.profile === "admin"}
                        updateTicketList={this.updateTicketList}
                    />
                )}
                {this.state.deleteConfirmation && (
                    <>
                        <div className='deletePopupContainer'>
                            <p>
                                Are you sure you want to delete this ticket?{" "}
                                <br />{" "}
                                <small>{"YOU CAN'T UNDO THIS ACTION!"}</small>
                            </p>
                            <div
                                id='options'
                                onClick={this.submitDelete}>
                                YES!
                            </div>
                            <div
                                id='options'
                                onClick={this.resetshowTicketForm}>
                                NO!
                            </div>
                        </div>
                        <div className='popupOverlay'> </div>
                    </>
                )}
            </div>
        );
    }
}

Pending.propTypes = {
    handleTicketCounter: PropTypes.func.isRequired,
    pendingsStatus: PropTypes.number.isRequired,
    profile: PropTypes.string.isRequired,
};

export default Pending;
