import PropTypes from "prop-types";

const DropDown = ({ name, handleChange, children, defaultValue }) => (
    <>
        <label className='label'> {name.toUpperCase()} </label>
        <div className='optionDropDown'>
            <select
                name={name}
                defaultValue={defaultValue}
                onChange={(e) => handleChange(e)}>
                {children}
            </select>
        </div>
    </>
);

DropDown.propTypes = {
    name: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    children: PropTypes.element,
    defaultValue: PropTypes.string,
};

export default DropDown;
