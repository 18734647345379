import React from "react";
import $ from "jquery";
import "../Pending/style.css";
import renderCheckBoxes from "../Helpers/renderCheckBoxes";
import SearchBar from "../CommonComponents/SearchBar";
import "../../css/cards.css";
import GenerateTicketTable from "../utils/generateTicketTable";
import filterAll from "../utils/filterAll";
import filterBy from "../utils/filterBy";
import TicketTemplate from "../TicketTemplate";
import "./style.css";
import config from "../../main-config.json";
const URL = config.failures_api_url;

const CHECKBOX_QUERY_SELECTOR = "input[type='checkbox']";

class Completed extends React.Component {
    constructor() {
        super();

        this.state = {
            ticket: [],
            filteredTicket: [],
            groupByTicket: [],
            itemData: {},
            search: "",
            filterAll: [],
            filterBy: "",
            checkboxes: ["room", "assistant", "cause"],
            department: ["electronic", "other"],
            showPendings: true,
            showTicketForm: false,
            hasSearched: false,
        };

        this.handleCheckboxFilter = this.handleCheckboxFilter.bind(this);
        this.handleDepartmentType = this.handleDepartmentType.bind(this);
    }

    handleCheckboxFilter(event) {
        const checkbox = event.target.name;
        const isChecked = event.target.checked;

        document
            .getElementById("groupBy")
            .querySelectorAll(CHECKBOX_QUERY_SELECTOR)
            .forEach((el) => {
                el.checked = false;
                this.setState({
                    filterBy: "",
                });
            });

        document
            .getElementById("groupBy")
            .querySelectorAll(CHECKBOX_QUERY_SELECTOR)
            .forEach((el) => {
                if (el.name === checkbox && el.checked !== isChecked) {
                    el.checked = true;
                    this.setState({
                        filterBy: checkbox,
                    });
                }
            });
    }

    handleDepartmentType = (event) => {
        const checkbox = event.target.name;
        // const isChecked = event.target.checked;
        const { ticket } = this.state;
        document
            .getElementById("department")
            .querySelectorAll(CHECKBOX_QUERY_SELECTOR)
            .forEach((el) => {
                el.checked = false;
                if (el.name === checkbox) {
                    el.checked = true;
                    let filteredTicket = filterBy(
                        ticket,
                        el.name,
                        "department"
                    );
                    this.setState({
                        filteredTicket,
                        showPendings: true,
                        search: "",
                        hasSearched: false,
                    });
                }
            });
    };

    viewTicket = (itemData) => {
        this.setState(
            {
                itemData,
            },
            () => {
                this.setState({
                    showTicketForm: true,
                    showPendings: false,
                    hasSearched: false,
                    showTickets: false,
                });
            }
        );
    };

    updateSearch(event) {
        let tempSearchTickets = [];

        if (event.target.value !== "") {
            this.setState({
                hasSearched: true,
                showTicketForm: false,
            });
        } else {
            this.setState({
                hasSearched: false,
                showPendings: true,
            });
        }

        this.setState(
            {
                search: event.target.value.substr(0, 20),
            },
            () => {
                if (this.state.filterBy === "") {
                    tempSearchTickets = filterAll(
                        this.state.filteredTicket,
                        this.state.search
                    );
                } else {
                    let tempFilterBy = this.state.filterBy;

                    tempSearchTickets = filterBy(
                        this.state.filteredTicket,
                        this.state.search,
                        tempFilterBy
                    );
                }

                this.setState({
                    showPendings: true,
                    groupByTicket: tempSearchTickets,
                });
            }
        );
    }

    showPendingTickets = (ticketsToMap) =>
        ticketsToMap
            .map((item) => (
                <GenerateTicketTable
                    key={item.id}
                    item={item}
                    viewTicket={this.viewTicket}
                />
            ))
            .sort((a, b) =>
                a.item < b.item ? 1 : -1
            ); /*This sorts the completed array so the newest will start always from top*/

    resetshowTicketForm = () => {
        this.setState({
            showTicketForm: false,
            showPendings: true,
        });
    };

    componentDidMount() {
        $.ajax({
            type: "POST",
            url: URL + "/ticket/fetchPendingTickets.php",
            data: {
                status: "completed",
            },
            success: (response) => {
                if (response.message === "No Pending Results!") {
                    this.setState({
                        showTickets: false,
                    });
                } else {
                    this.setState(
                        {
                            ticket: response.ticket,
                            showTickets: true,
                        },
                        () => {
                            let filteredTicket = filterBy(
                                response.ticket,
                                "electronic",
                                "department"
                            );
                            this.setState({
                                filteredTicket,
                            });
                        }
                    );
                }
            },
        });
    }

    render() {
        return (
            <div className='back'>
                {this.state.showPendings && (
                    <SearchBar
                        searchInput={this.state.search}
                        updateSearch={this.updateSearch.bind(this)}
                    />
                )}
                {this.state.showPendings && (
                    <>
                        <div className='filterSearchContainer row-reverse'>
                            <div className='filterCheckBoxes'>
                                <h2>Department</h2>
                                <div id='department'>
                                    {renderCheckBoxes(
                                        this.state.department,
                                        this.handleDepartmentType.bind(this)
                                    )}
                                </div>
                                {/* <h2>Group By</h2>
                                <div id="groupBy">
                                    {renderCheckBoxes(
                                        this.state.checkboxes,
                                        this.handleCheckboxFilter.bind(this)
                                    )}
                                </div> */}
                            </div>

                            <table>
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Store</th>
                                        <th>Country</th>
                                        <th>Room</th>
                                        <th>GO Name</th>
                                        <th>Completed</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.hasSearched &&
                                    this.state.groupByTicket.length >= 1 ? (
                                        this.showPendingTickets(
                                            this.state.groupByTicket
                                        )
                                    ) : this.state.hasSearched &&
                                      this.state.groupByTicket.length < 1 ? (
                                        <tr>
                                            <td></td>
                                            <td className='resultP'>
                                                No results found!
                                            </td>
                                        </tr>
                                    ) : (
                                        this.showPendingTickets(
                                            this.state.filteredTicket
                                        )
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </>
                )}
                {this.state.showTicketForm && (
                    <TicketTemplate
                        data={this.state.itemData}
                        calledFromCompleted={true}
                        resetshowTicketForm={this.resetshowTicketForm}
                    />
                )}
            </div>
        );
    }
}

export default Completed;
