import store from "./roomsPerStore";

const GenerateRoomList = (selectedStore) => {
    let storeData = store.filter((item) => item.name === selectedStore);

    return (
        <>
            <option value='AHS'>AHS</option>
            <option value='AHS'>CONTROL ROOM</option>
            {storeData[0].rooms.sort().map((item, index) => (
                <option
                    key={index}
                    value={item}>
                    {item}
                </option>
            ))}
        </>
    );
};

export default GenerateRoomList;
