import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import $ from "jquery";
import "./App.css";
import NavBar from "./Components/NavBar";
import Pending from "./Components/Pending/index";
import Completed from "./Components/Completed/index";
import TicketForm from "./Components/TicketForm";
import { login, logout } from "./Components/utils/JWTAuth.js";
import { jwtDecode } from "jwt-decode";
import config from "./main-config.json";
const URL = config.failures_api_url;

//TODO Transform all clasess to functions
class App extends React.Component {
    constructor() {
        super();
        this.mounted = React.createRef(false);
        this.state = {
            ticketCounter: 0,
            loginProfile: null,
            errorMessage: "",
            isAuth: false,
            causes: [],
            issues: [],
            ismounted: false,
        };
        // checkLoginStatus();
        // verify a token symmetric - synchronous
        const token = localStorage.getItem("access_token");
        const isAuth = localStorage.getItem("iA");
        // let jwtDecode = require("jwt-decode");
        // console.log({ causes: this.state.causes })
        if (token) {
            let decoded = jwtDecode(token);
            let loginProfile = decoded.data.username;
            // console.log(decoded.data.username);

            this.state.loginProfile = loginProfile;
            this.state.isAuth = isAuth;
        }
    }

    handleLogin = (data) => {
        async function getLoginResult() {
            const response = await login(data);
            handleLoginResponse(response);
        }

        getLoginResult();

        const handleLoginResponse = (response) => {
            // console.log(response);

            if (response.message === "Successful login.") {
                this.setState({
                    loginProfile: response.username,
                    isAuth: true /**iA sets to true if the users credentials are correct */,
                });
                localStorage.setItem("iA", "true");
            } else {
                this.setState(
                    {
                        errorMessage: "Login failed!",
                    },
                    () => {
                        setTimeout(() => {
                            this.setState({
                                errorMessage: "",
                            });
                        }, 1500);
                    }
                );
            }
        };
    };

    handleLogout = () => {
        logout();

        this.setState({
            loginProfile: null,
            isAuth: false /**isAuth sets to false due to incorrect credentials */,
        });
    };

    handleTicketCounter = (ticketCounter) => {
        this.setState({
            ticketCounter,
        });
    };

    componentDidMount = () => {
        const token = localStorage.getItem("access_token");

        if (token) {
            let decoded = jwtDecode(token);
            let loginProfile = decoded.data.username;
            // console.log(decoded.data.username);

            this.setState({
                loginProfile,
                ismounted: true,
            });
        }

        $.ajax({
            type: "POST",
            url: URL + "/ticket/fetchPendingTickets.php",
            data: {
                status: "pending",
            },
            success: (response) => {
                // console.log(response)
                if (response.message === "No Pending Results!") {
                    this.setState({
                        ticketCounter: 0,
                    });
                } else {
                    this.setState({
                        ticketCounter: response.ticket.length - 1,
                    });
                }
            },
        });
    };

    render() {
        return (
            <BrowserRouter>
                <NavBar
                    pendingsStatus={this.state.ticketCounter}
                    handleLogin={this.handleLogin}
                    loginProfile={this.state.loginProfile}
                    handleLogout={this.handleLogout}
                />
                {this.state.isAuth && (
                    <Routes>
                        <Route
                            exact
                            path='/'
                            replace
                            element={
                                <Pending
                                    handleTicketCounter={
                                        this.handleTicketCounter
                                    }
                                    pendingsStatus={this.state.ticketCounter}
                                    profile={this.state.loginProfile}
                                />
                            }
                        />
                        <Route
                            path='/form'
                            replace
                            element={
                                <TicketForm
                                    causes={this.state.causes}
                                    handleTicketCounter={
                                        this.handleTicketCounter
                                    }
                                    pendingsStatus={this.state.ticketCounter}
                                />
                            }
                        />
                        <Route
                            path='/completed'
                            element={<Completed />}
                        />
                    </Routes>
                )}
                {/**
                Authenticates the user.
                 */}
                {this.state.errorMessage === "Login failed!" && (
                    <>
                        <div
                            className='loginPopupContainer'
                            style={{
                                border: "4px solid #da1a35",
                                borderRadius: "1em",
                                background: "#0a0a0a",
                            }}>
                            Wrong username or password!
                        </div>
                    </>
                )}
            </BrowserRouter>
        );
    }
}

export default App;
