import axios from "axios";
import config from "../../main-config.json";
const URL = config.failures_api_url;
const LOGIN_ENDPOINT = URL + "/login.php";

const login = async (data) => {
    try {
        // console.log( data );

        let response = await axios.post(LOGIN_ENDPOINT, data);

        // console.log( response );

        if (
            response.status === 200 &&
            response.data.jwt &&
            response.data.expireAt
        ) {
            let jwt = response.data.jwt;
            let expire_at = response.data.expireAt;

            // console.log( jwt );

            localStorage.setItem("access_token", jwt);
            localStorage.setItem("expire_at", expire_at);

            return response.data;
        } else {
            return response;
        }
    } catch (e) {
        // console.log( e );
    }
};

const logout = () => {
    // console.log( "logout" );
    localStorage.removeItem("access_token");
    localStorage.setItem("iA", "false");
    localStorage.removeItem("expire_at");
};

export { login, logout };
