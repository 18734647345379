import React from "react";
import $ from "jquery";
import "./style.css";
import DropDown from "./Dropdown";
import TextArea from "./Textarea";
import GenerateCountryList from "../utils/generateCountryList";
import GenerateStoreList from "../utils/generateStoreList";
import GenerateRoomList from "../utils/generateRoomList";
import timeHelper from "../utils/timeHelper";
import renderCheckBoxes from "../Helpers/renderCheckBoxes";
import config from "../../main-config.json";
import PropTypes from "prop-types";

const URL = config.failures_api_url;

const CHECKBOX_QUERY_SELECTOR = "input[type='checkbox']";

class TicketForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            country: "GREECE",
            store: "Aigaleo GO",
            room: "JACK THE RIPPER",
            report: "",
            cause: "",
            assistant: "",
            client: "",
            isSolved: false,
            solution: "",
            createdDate: "",
            completedDate: "1970-01-01",
            status: "pending",
            department: "electronic",
            checkboxes: ["electronic", "other"],
            errorMessage: "",
            successMessage: "",
        };

        this.initialState = { ...this.state };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCheckboxFilter = this.handleCheckboxFilter.bind(this);
        this.resetForm = this.resetForm.bind(this);
    }

    resetForm() {
        this.setState(this.initialState);
        this.myFormRef.reset();
    }

    handleCheckboxFilter(e) {
        const checkbox = e.target.name;
        // const isChecked = e.target.checked;

        document
            .querySelectorAll(CHECKBOX_QUERY_SELECTOR)
            .forEach((element) => {
                element.checked = false;
            });

        document
            .querySelectorAll(CHECKBOX_QUERY_SELECTOR)
            .forEach((element) => {
                if (element.name === checkbox) {
                    element.checked = true;
                    this.setState({
                        department: checkbox,
                    });
                }
            });
    }

    handleChange(event) {
        const { name, value } = event.target;
        console.log(name, value);

        // let initialStore;
        // switch (name) {
        //     case "country":
        //         initialStore = (store.find(loc => loc.country === value))

        //         this.setState(prev => ({ ...prev, country: value, store: initialStore }))
        //         return;
        //     case "store":
        //         initialStore = (store.filter(loc => loc.country === this.state.country))
        //         const selectedStore = (initialStore.find(str => str.name === value))
        //         this.setState(prev => ({...prev , store : selectedStore}))

        // }
        if (name === "country") {
            this.setState(
                {
                    [name]: value,
                },
                () => {
                    let store = document.getElementsByName("store")[0].value;
                    this.setState(
                        {
                            store,
                        },
                        () => {
                            let room =
                                document.getElementsByName("room")[0].value;
                            this.setState({
                                room,
                            });
                        }
                    );
                }
            );
        } else if (name === "store") {
            this.setState(
                {
                    [name]: value,
                },
                () => {
                    let room = document.getElementsByName("room")[0].value;
                    this.setState({
                        room,
                    });
                }
            );
        }

        this.setState({
            [name]: value,
        });
    }

    handleCheckbox = (event) => {
        const { name, value } = event.target;

        let completedDate = timeHelper();

        if (name === "solution" && value.length > 0) {
            this.setState({
                isSolved: true,
                [name]: value,
                status: "completed",
                completedDate: completedDate,
            });
        } else {
            this.setState({
                isSolved: false,
                status: "pending",
                solution: "",
            });
        }
    };

    componentDidMount() {
        document.querySelectorAll(CHECKBOX_QUERY_SELECTOR).forEach((item) => {
            if (item.name === "electronic") {
                item.checked = true;
            }
        });

        let currentDate = timeHelper();

        this.setState({
            createdDate: currentDate,
        });
    }

    handleTicketCounterForm = () => {
        let newTicketCounter = 0;
        let oldTicketCounter = this.props.pendingsStatus;

        if (this.state.solution === "") {
            newTicketCounter = oldTicketCounter + 1;
            this.props.handleTicketCounter(newTicketCounter);
        } else {
            this.props.handleTicketCounter(oldTicketCounter);
        }
    };

    handleSubmit = (event) => {
        event.preventDefault();

        if (
            this.state.report === "" ||
            this.state.assistant === "" ||
            this.state.client === ""
        ) {
            this.setState({
                errorMessage: "Please fill the blanks",
                successMessage: "",
            });
        } else {
            $.ajax({
                type: "POST",
                url: URL + "/ticket/insertTicket.php",
                data: {
                    ...this.state,
                },
                success: (response) => {
                    if (response.message === "success") {
                        this.handleTicketCounterForm(this.state.solution);
                        this.resetForm();
                        this.setState({
                            successMessage: "Ticket successfully submitted!",
                        });
                    } else {
                        this.setState({
                            errorMessage: "A problem has occurred",
                            successMessage: "",
                        });
                    }
                },
                error: (error) => {
                    this.setState({
                        errorMessage: error.message,
                        successMessage: "",
                    });
                },
            });
        }
    };

    render() {
        return (
            <div className='back'>
                <form
                    className='formContainer'
                    ref={(el) => (this.myFormRef = el)}>
                    <div className='formItem'>
                        <DropDown
                            name='country'
                            handleChange={this.handleChange}>
                            <GenerateCountryList />
                        </DropDown>
                        <DropDown
                            name='store'
                            handleChange={this.handleChange}>
                            <GenerateStoreList
                                selectedCountry={this.state.country}
                            />
                        </DropDown>
                        <DropDown
                            name='room'
                            handleChange={this.handleChange}>
                            {GenerateRoomList(this.state.store)}
                        </DropDown>
                        <p>
                            DATE:
                            <span> {this.state.createdDate} </span>
                        </p>
                        <div id='ticketForm__checkbox'>
                            {renderCheckBoxes(
                                this.state.checkboxes,
                                this.handleCheckboxFilter.bind(this)
                            )}
                        </div>
                    </div>
                    <div className='formItem-2'>
                        <div className='row'>
                            <TextArea
                                name='report'
                                handleChange={this.handleChange}
                            />
                            <TextArea
                                name='cause'
                                handleChange={this.handleChange}
                            />
                        </div>
                        <div className='row'>
                            <TextArea
                                name='assistant'
                                handleChange={this.handleChange}
                            />
                            <TextArea
                                name='client'
                                handleChange={this.handleChange}
                            />
                        </div>
                        <div className='solutionInput'>
                            <label className='label'> SOLUTION </label>
                            <input
                                autoComplete='true'
                                name='solution'
                                cols='10'
                                rows='5'
                                onChange={this.handleCheckbox}
                            />
                            <p className='checkBoxStyle'>
                                <input
                                    autoComplete='true'
                                    id='test1'
                                    name='isSolved'
                                    type='checkbox'
                                    readOnly
                                    checked={this.state.isSolved}
                                    onChange={this.handleCheckbox}
                                />
                                <label htmlFor='test1'> </label>
                            </p>
                        </div>
                        <p className='successMessage'>
                            {" "}
                            {this.state.successMessage}{" "}
                        </p>
                        <p className='errorMessage'>
                            {" "}
                            {this.state.errorMessage}{" "}
                        </p>
                        <p
                            className='submitButton'
                            onClick={this.handleSubmit}>
                            SUBMIT
                        </p>
                    </div>
                </form>
            </div>
        );
    }
}

TicketForm.propTypes = {
    handleTicketCounter: PropTypes.func.isRequired,
    pendingsStatus: PropTypes.number.isRequired,
};

export default TicketForm;
