import React from "react";
import { Link } from "react-router-dom";
import Login from "../Login";
import Logo from "../../assets/logo.png";
import HomeImg from "../../assets/addbutton.svg";
import PendingImg from "../../assets/pending.svg";
import CompletedImg from "../../assets/completed.svg";
import "./style.css";
import PropTypes from "prop-types";

class NavBar extends React.Component {
    handleLogin = (data) => {
        this.props.handleLogin(data);
    };

    handleLogout = () => {
        this.props.handleLogout();
    };

    render() {
        return (
            <nav
                className='row'
                id='top'
                style={{
                    alignItems: "center",
                    justifyContent: "space-around",
                }}>
                <div>
                    <Link to='/'>
                        <img
                            className='imgFluid'
                            src={Logo}
                            alt=''
                        />
                    </Link>
                </div>

                <ul className='menuLinks'>
                    <li>
                        <Link
                            to='/form'
                            replace>
                            <img
                                className='navBarplus'
                                src={HomeImg}
                                alt=''
                            />
                        </Link>
                        <div
                            className='new'
                            id='perspective-text'>
                            <p
                                style={{
                                    color: "#fff",
                                    textDecoration: "none",
                                }}>
                                Add
                            </p>
                        </div>
                    </li>
                    <li>
                        <Link
                            to='/'
                            replace>
                            <img
                                className='navBarIcons'
                                src={PendingImg}
                                alt=''
                            />
                            <div className='pendingsCounter'>
                                {this.props.pendingsStatus + 1}
                            </div>
                        </Link>
                        <div
                            className='complete'
                            id='perspective-text'>
                            <p style={{ color: "#fff" }}> Pending</p>
                        </div>
                    </li>
                    <li>
                        <Link
                            to='completed'
                            replace>
                            <img
                                className='navBarIcons'
                                src={CompletedImg}
                                alt=''
                            />
                        </Link>
                        <div
                            className='complete'
                            id='perspective-text'>
                            <p style={{ color: "#fff" }}>Completed</p>
                        </div>
                    </li>
                </ul>
                <Login
                    handleLogin={this.handleLogin}
                    handleLogout={this.handleLogout}
                    loginProfile={this.props.loginProfile}
                />
            </nav>
        );
    }
}

NavBar.propTypes = {
    handleLogin: PropTypes.func.isRequired,
    handleLogout: PropTypes.func.isRequired,
    loginProfile: PropTypes.string,
    pendingsStatus: PropTypes.number,
};

export default NavBar;
