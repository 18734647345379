import CheckBoxes from "../CommonComponents/Checkboxes";
import "../CommonComponents/Checkboxes/checkBoxes.css";

const renderCheckBoxes = (
    checkboxes,
    handleCheckboxFilter,
    handleDepartmentType
) =>
    checkboxes.map((item, index) => (
        <CheckBoxes
            key={index}
            name={item}
            handleCheckboxFilter={
                handleCheckboxFilter ? handleCheckboxFilter : undefined
            }
            handleDepartmentType={
                handleDepartmentType ? handleDepartmentType : undefined
            }
        />
    ));

export default renderCheckBoxes;
