import store from "./roomsPerStore";

const countries = [
    ...new Set(
        store.map((country) => {
            return country.country;
        })
    ),
];

const GenerateCountryList = () =>
    countries.sort().map((item, index) => (
        <option
            key={index}
            value={item}>
            {item}
        </option>
    ));

export default GenerateCountryList;
