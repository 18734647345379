import "./searchBar.css";
import PropTypes from "prop-types";

const SearchBar = ({ searchInput, updateSearch }) => {
    return (
        <div
            id='searchBar'
            className='searchBar___container'>
            <input
                autoComplete='true'
                id='search'
                name='search'
                type='text'
                placeholder="What're we looking for? "
                value={searchInput}
                onChange={(e) => updateSearch(e)}
            />
            <input
                autoComplete='true'
                id='search_submit'
                value='Rechercher'
                type='submit'
                disabled={true}
            />
        </div>
    );
};

SearchBar.propTypes = {
    searchInput: PropTypes.string.isRequired,
    updateSearch: PropTypes.func.isRequired,
};

export default SearchBar;
