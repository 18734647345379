import PropTypes from "prop-types";

const CheckBoxes = ({
    name,
    handleCheckboxFilter,
    checked,
    isDisabled,
    handleDepartmentType,
}) => (
    <div className='checkBoxes__item'>
        <div>{name.toUpperCase()} </div>
        <input
            autoComplete='true'
            key={name}
            id={name}
            name={name}
            type='checkbox'
            onChange={
                handleCheckboxFilter
                    ? handleCheckboxFilter
                    : handleDepartmentType
            }
            checked={name === checked ? true : undefined}
            disabled={isDisabled ? true : undefined}
            defaultChecked={name === "electronic" ? true : undefined}
        />
        <label htmlFor={name}> {name} </label>
    </div>
);

CheckBoxes.propTypes = {
    name: PropTypes.string.isRequired,
    handleCheckboxFilter: PropTypes.func,
    checked: PropTypes.string,
    isDisabled: PropTypes.bool,
    handleDepartmentType: PropTypes.func,
};

export default CheckBoxes;
